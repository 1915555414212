import React from 'react'
import './InkPageHeader.styl'
import Paint from './../../Assets/svgs/paint3.svg'
import { Parallax } from 'react-scroll-parallax';
const InkPageHeader = props => (
  <section className='InkPageHeader'>
    <Parallax
      offsetYMax={20}
      offsetYMin={-20}
      className='_px'
      slowerScrollRate
    >
      <figure style={{
          backgroundImage : `url('${props.image}')`,
          backgroundSize : 'cover',
          backgroundPosition : 'center',
          backgroundAttachment:'scroll',
          height : '100vh'
        }} 
      />
    </Parallax>
    <img src={Paint} alt='ink' />
    {props.children && <aside>{props.children}</aside>}
  </section>
)
export default InkPageHeader
