import React from 'react'
import PageTransition from 'gatsby-plugin-page-transitions'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import InkPageHeader from '../UI/InkPageHeader/InkPageHeader'
import Blank from '../UI/Blank/Blank'
import { Title, PageTitle, Text } from '../UI/Typing'
import rectBlank from '../Assets/rectBlank.png'
import { Parallax } from 'react-scroll-parallax'
import { LineButton } from '../UI/Button/Button'
import './index.styl'
import Img1 from '../Assets/home/home-storia.png'
// Immagine stanze
import Img2 from '../Assets/home/home-stanze.png'
// Immagine cucina
import Img3 from '../Assets/home/home-cucina.png'
import Img4 from '../Assets/home/014.jpg'
import HeadImg from '../Assets/home/home1.png'

import brush from './../Assets/brush.svg'

const Brush = props => (
  <div className='Brush'>
    <img src={brush} alt='' />
    <strong>{props.label}</strong>
  </div>
)

const HomeRow = props => (
  <div className={`reveal HomeRow ${props.align}`}>
    <aside>
      <Parallax offsetYMax={20} offsetYMin={-20}>
        <Blank onHeight sizer={rectBlank} image={props.image} />
      </Parallax>
    </aside>
    <div>
      <Brush label={props.label} />
      <Title>{props.title}</Title>
      <Text>{props.description}</Text>
      <div>
        <LineButton to={props.link}>
          <FormattedMessage id='more' />
        </LineButton>
      </div>
    </div>
  </div>
)

const IndexPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <InkPageHeader image={HeadImg}>
        <PageTitle>
          <FormattedMessage id='homeTitle' />
        </PageTitle>
        <Text>
          <FormattedMessage id='homeDescription' />
        </Text>
      </InkPageHeader>
      <section>
        <HomeRow
          align='left'
          title={<FormattedMessage id='history' />}
          description={<FormattedMessage id='homeDescription1' />}
          image={Img1}
          link='/story'
          label={1}
        />
        <HomeRow
          align='right'
          title={<FormattedMessage id='cook' />}
          description={<FormattedMessage id='homeDescription2' />}
          image={Img3}
          link='/cook'
          label={2}
        />
        <HomeRow
          align='left'
          title={<FormattedMessage id='wine' />}
          description={<FormattedMessage id='homeDescription3' />}
          image={Img4}
          link='/wines'
          label={3}
        />
        <HomeRow
          align='right'
          title={<FormattedMessage id='rooms' />}
          description={<FormattedMessage id='homeDescription4' />}
          image={Img2}
          link='/rooms'
          label={4}
        />
      </section>
    </PageTransition>
  </Layout>
)

export default IndexPage
