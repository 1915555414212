import React from 'react'
import './Typing.styl'

const PageTitle = props => <h1 className='PageTitle'>{props.children}</h1>
const Title = props => <h2 className='Title'>{props.children}</h2>
const SubTitle = props => <h3 className='Title'>{props.children}</h3>
const InnerTitle = props => <h4 className='Title'>{props.children}</h4>
const Text = props => <div className='Text'>{props.children}</div>

export {SubTitle,Title,PageTitle,InnerTitle,Text}
