import React, { Component } from 'react'

export default class Animotion extends Component {
  state = { isIn: false }
  componentWillUnmount() {
    this.observer.disconnect()
  }
  componentDidMount() {
    require('intersection-observer')
    this.observer = new IntersectionObserver(
      (entries, observer) => this.handleObserver(entries, observer),
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.45,
      },
    )
    this.observer.observe(this.element)
  }
  handleObserver(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        this.setState({ isIn: true })
        //observer.unobserve(entry.target)
      } else this.setState({ isIn: false })
    })
  }

  render() {
    return (
      <div
        ref={el => (this.element = el)}
        className={`Animotion ${this.state.isIn ? 'ok' : ''}`}
      >
        {this.props.children}
      </div>
    )
  }
}
